.vpsb {
  table.table {
    color: var(--vpsb-font-color);
  }

  .table-borderless > tbody > tr > td,
  .table-borderless > tbody > tr > th,
  .table-borderless > tfoot > tr > td,
  .table-borderless > tfoot > tr > th,
  .table-borderless > thead > tr > td,
  .table-borderless > thead > tr > th {
      border: none !important;
  }

  table.table-white {
    background-color: var(--vpsb-background-secondary);
    color: var(--vpsb-font-color);

    th {
      background-color: var(--vpsb-background-soft-primary);
    }

    th.hoster-row {
      font-size: 1.5rem;
      background-color: var(--vpsb-primary-medium-light);
    }

    tr.header-row-muted th {
      color: var(--vpsb-font-color);
      font-style: italic;
    }

    td {
      a:not(:has(i),.btn-success,.btn-primary,.btn-danger,.btn-warning) {
        color: var(--vpsb-link-color-soft-bg);
      }
    }
  }

  table.table-white.table-hover {
    tr {
      &:hover {
        color: var(--vpsb-font-color);
        background-color: var(--vpsb-background);
      }
    }
  }

  table.table-bi-dim {
    color: var(--vpsb-font-color);
    background-color: var(--vpsb-background-secondary);

    tr.header-row > th {
      background-color: var(--vpsb-background-soft-primary);
    }

    td.feature-display:not(.success,.danger) {
      color: var(--vpsb-almost-black);
      background-color: var(--vpsb-light-gray);
    }
  }

  table {
    th.sub-heading {
      background-color: var(--vpsb-primary-muted);
      font-style: italic;
      color: var(--vpsb-font-color);
    }

    td.heading {
      font-weight: 600;
    }

  }

  table.table-grades {
    color: var(--vpsb-font-color);
    background-color: var(--vpsb-background-secondary);

    th {
      background-color: var(--vpsb-background-soft-primary);
    }

    tr.header-row > th {
      font-style: italic;
    }

    td.grade-cell {
      color: black;

      a {
        color: var(--vpsb-primary);
      }
    }
  }

  tr.grade-background {
    color: black;

    a {
      color: var(--vpsb-primary);
    }
  }

  td.clickable-cell a {
    display:block;
    width:100%;
    text-decoration: none;
  }

  td.center {
    text-align: center;
    vertical-align: middle;
  }

  tr.sortable-table th:hover {
    cursor: pointer;
  }

  tr.borderless-top td {
    border-top: 0px;
  }

  table.attr-value-pairs {
    // width: 450px;

    tr {
      td:first-child {
        text-align: left;
        font-weight: 600;
      }

      td:last-child {
        text-align: left;
      }

      td {
        padding: 0px 0px;
      }
    }

    &.generous-padding {
      td {
        padding-right: 1.4rem;
        padding-bottom: 1rem;
      }
    }
  }

  table {
    tr, td {
      .success {
        color: var(--vpsb-almost-black);
        background-color: #DFF0D8 !important;
      }

      .danger {
        color: var(--vpsb-almost-black);
        background-color: #F2DEDE !important;
      }
    }
  }
}